import { __awaiter, __generator } from "tslib";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { audioPlayClick, getCartList, } from "@/core/helpers/custom";
import { useRoute } from "vue-router";
import { getRelatedProduct, showProduct } from "@/api/ProductApi";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { Modal } from "bootstrap";
import { removeWishlist, wishlist } from "@/api/WishlistApi";
import useNotyf from "@/composable/useNotyf";
import { addProductToCart } from "@/api/CartApi";
import BaseImageSlider from "@/components/BaseImageSlider.vue";
export default defineComponent({
    name: "productsRelatedhow",
    components: { BaseImageSlider: BaseImageSlider },
    setup: function () {
        var _this = this;
        var main = ref();
        var thumbs = ref();
        var addToCartItem = ref({});
        var product = ref({});
        var productRelated = ref({});
        var productsRelated = ref([]);
        var route = useRoute();
        var fullProductRoute = ref("");
        var store = useStore();
        var isUserAuthenticated = computed(function () {
            return store.getters.isUserAuthenticated;
        });
        var notification = useNotyf();
        var productId = computed(function () {
            return route.params.productId;
        });
        var loading = ref({
            main: false,
            relatedProduct: false,
            addToCart: false,
            wishlist: false,
        });
        var getProduct = function () { return __awaiter(_this, void 0, void 0, function () {
            var productId;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        productId = route.params.productId;
                        loading.value.main = true;
                        if (!productId) return [3 /*break*/, 2];
                        return [4 /*yield*/, showProduct(route.params.productId).then(function (res) {
                                var _a;
                                product.value = res.data.data;
                                (_a = product.value) === null || _a === void 0 ? void 0 : _a.options.forEach(function (option, x) {
                                    option.values.forEach(function (value) {
                                        addToCartItem.value.options[x] = {
                                            option_id: option.id,
                                            option_value_id: value.id,
                                            price: value.price_prefix ? value.price : value.price * -1,
                                        };
                                    });
                                });
                            })];
                    case 1:
                        _a.sent();
                        loading.value.main = false;
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); };
        var getListOfRelatedProduct = function (payload) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        loading.value.relatedProduct = true;
                        // topLoadingToggle(true);
                        return [4 /*yield*/, getRelatedProduct(payload)
                                .then(function (res) {
                                productsRelated.value = res.data.data;
                            })
                                .catch(function () {
                                "_";
                            })];
                    case 1:
                        // topLoadingToggle(true);
                        _a.sent();
                        loading.value.relatedProduct = false;
                        return [2 /*return*/];
                }
            });
        }); };
        var currentPageChange = function (val) {
            getListOfRelatedProduct({
                page: val,
                id: productId,
                limit: 5,
            });
        };
        var quickView = function (id) { return __awaiter(_this, void 0, void 0, function () {
            var productModal, productRes;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        fullProductRoute.value = window.location.host + "/product/" + id;
                        productModal = new Modal(document.getElementById("kt_modal_product_detail"), {
                            keyboard: false,
                        });
                        return [4 /*yield*/, showProduct(id)];
                    case 1:
                        productRes = _a.sent();
                        productRelated.value = productRes.data.data;
                        productModal.show();
                        return [2 /*return*/];
                }
            });
        }); };
        var wishlistOperation = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        loading.value.wishlist = true;
                        if (!((_a = product.value) === null || _a === void 0 ? void 0 : _a.is_favourite)) return [3 /*break*/, 2];
                        return [4 /*yield*/, removeWishlist({
                                product_id: (_b = product.value) === null || _b === void 0 ? void 0 : _b.id,
                            })
                                .then(function () {
                                notification.success("This item has been removed from your wishlist.");
                                product.value.is_favourite = false;
                            })
                                .catch(function () {
                                "_";
                            })];
                    case 1:
                        _d.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, wishlist({
                            product_id: (_c = product.value) === null || _c === void 0 ? void 0 : _c.id,
                        })
                            .then(function () {
                            notification.success("This item has been added to your wishlist.");
                            product.value.is_favourite = true;
                        })
                            .catch(function () {
                            "_";
                        })];
                    case 3:
                        _d.sent();
                        _d.label = 4;
                    case 4:
                        loading.value.wishlist = false;
                        return [2 /*return*/];
                }
            });
        }); };
        var addToCart = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        audioPlayClick("AddToCart");
                        loading.value.addToCart = true;
                        return [4 /*yield*/, addProductToCart(addToCartItem.value)
                                .then(function (res) {
                                var _a;
                                notification.success((_a = res.data.message) !== null && _a !== void 0 ? _a : "Add to cart successfully");
                            })
                                .catch(function () {
                                "_";
                            })];
                    case 1:
                        _a.sent();
                        getCartList();
                        loading.value.addToCart = false;
                        return [2 /*return*/];
                }
            });
        }); };
        watch(function () { return route.params; }, function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                getProduct();
                return [2 /*return*/];
            });
        }); });
        var closeModal = function () {
            getProduct();
        };
        onMounted(function () {
            setCurrentPageBreadcrumbs("Product Details", ["Product"]);
            addToCartItem.value = {
                product_id: Number(route.params.productId),
                quantity: 1,
                options: [],
            };
            getProduct();
            getListOfRelatedProduct({
                id: productId.value,
                limit: 5,
            });
            fullProductRoute.value =
                window.location.host + "/product/" + route.params.productId;
        });
        return {
            main: main,
            thumbs: thumbs,
            route: route,
            loading: loading,
            product: product,
            fullProductRoute: fullProductRoute,
            productsRelated: productsRelated,
            currentPageChange: currentPageChange,
            isUserAuthenticated: isUserAuthenticated,
            quickView: quickView,
            wishlistOperation: wishlistOperation,
            addToCart: addToCart,
            addToCartItem: addToCartItem,
            productRelated: productRelated,
            closeModal: closeModal,
        };
    },
});
